<template>
  <div id="students" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns is-multiline">
        <div class="column is-12">
          <SdInput
            type="text"
            label="Search"
            v-model="searchName"
            placeholder="Name"
          />
        </div>
        <div class="column is-12">
          <b-table :data="data" :paginated="isPaginated" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
              >{{ students.indexOf(props.row) + 1 }}</b-table-column
            >

            <b-table-column label="Name" v-slot="props">{{
              props.row.name
            }}</b-table-column>

            <b-table-column label="School Class" v-slot="props">{{
              props.row.schoolClass.name
            }}</b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                @initialize-info="initializeInfo(props.row)"
                @initialize-update="initializeUpdate(props.row)"
                @initialize-remove="initializeRemove(props.row)"
              />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>
    <!-- Delete -->
    <app-modal context="remove" @remove="remove" />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '../../components/SdInput'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'students',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Student Search',
      data: [],
      students: [],
      id: null,
      isPaginated: true,
      searchName: '',
    }
  },
  watch: {
    searchName(query) {
      if (query === '') {
        this.data = []
        return
      }
      const filterItems = (student) => {
        const even = (element) =>
          element.toLowerCase().match(query.toLowerCase())
        return student.name.split(' ').some(even)
      }

      const searchByPhoneNumber = (student) => {
        if (student.parent) {
          return [
            student.parent.phoneOfFather,
            student.parent.phoneOfMother,
          ].includes(query)
        } else {
          return false
        }
      }

      this.data = this.students.filter(
        (student) => filterItems(student) || searchByPhoneNumber(student)
      )
    },
  },
  methods: {
    initializeInfo(student) {
      this.$router.push(
        `/school/${this.schoolId}/school_class/${student.schoolClass.id}/student_info/${student.id}`
      )
    },
    initializeUpdate(student) {
      this.$router.push(`/school/${this.schoolId}/student/${student.id}`)
    },
    initializeRemove(student) {
      this.id = parseInt(student.id)
      this.openModal()
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deleteStudent($id: Int!) {
              deleteStudent(input: { id: $id }) {
                student {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deleteStudent.errors,
            'Successfully deleted.'
          ).then(() => {
            this.closeModal()
          })
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  components: {
    SdInput,
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/student/new`,
      },
    ])

    this.$apollo.addSmartQuery('students', {
      query: gql`
        query SearchStudentsInSchoolQuery($schoolId: Int!) {
          studentsInSchool(schoolId: $schoolId) {
            id
            name
            regId
            schoolClass {
              id
              name
            }
            parent {
              phoneOfFather
              phoneOfMother
            }
          }
        }
      `,
      error(error) {
        this.error = error.message
      },
      variables() {
        return { schoolId: this.schoolId }
      },
      update: (data) => data.studentsInSchool,
    })

    this.$apollo.queries.students.refetch()
  },
}
</script>
